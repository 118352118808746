import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import * as uuid from 'uuid';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RpsService } from 'src/app/core/services/rps.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/core/services/utils.service';
@Component({
  selector: 'app-modal-questionnaire',
  templateUrl: './modal-questionnaire.component.html',
  styleUrls: ['./modal-questionnaire.component.css']
})
export class ModalQuestionnaireComponent implements OnInit {


  percentage: number;
  questions: any[];
  currentQuestion: any;
  phonePrefix = '+1';
  token: any;
  Jwt: any;
  error: string;
  now: any;
  submit = false;
  loading = false;
  infoClient: any;
  @ViewChild('inputDate') inputDate: ElementRef;
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.currentQuestion.newPosition === 24) {
        this.onSubmit();
      } else {
        this.next();
      }
      if (this.submit) {
        this.closeModal();
      }

    }
  }
  constructor(
              private rpsService: RpsService,
              private route: ActivatedRoute,
              private router: Router,
              private utilService: UtilsService,
              public ref: DynamicDialogRef,
              public config: DynamicDialogConfig) {
    const  dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
    const today  = new Date();
    today.setDate(today.getDate() + 1);
    this.now = today.toLocaleDateString('fr-CA', dateOptions);
  }


  ngOnInit() {
    this.rpsService.signupAnonymo().then(token => {
      if (token) {
        this.infoClient = this.config.data;
        this.token = token;
        this.Jwt = token;
        this.getQuestions(token);
      }
    });


  }

  getQuestions(token) {
    this.rpsService.getAllQuestions(token).subscribe((res: any) => {
      res = res.data;
      res.sort((a, b) => {
        if (a.position > b.position) {
          return 1;
        }
        if (a.position < b.position) {
          return -1;
        }
        return 0;
      });
      let i = 1;
      res.forEach((element, index) => {
        element.newPosition = index + 1;
        element.text = null;
        if (index == 0 || index == 1 || index == 22 || index == 23) {
           element.newNumber = '';
        } else {
          element.newNumber = i;
          i++;
        }
      });
      this.questions = res;
      (this.questions[this.questions.length - 1]).isLast = true;
      if (this.infoClient) {
        this.questions.forEach(element => {
          if (element.newPosition === 1) {
            element.text = `${this.infoClient.client_name} ${this.infoClient.client_last_name}`;
          }
          if (element.newPosition === 2) {
            element.text = `${this.infoClient.client_estimated_date_ret}`;
          }
          if (element.newPosition === 23) {
            element.text = this.infoClient.client_email;
          }
          if (element.newPosition === 24) {
            element.text = this.infoClient.client_phone;
          }
        });
      }
      this.questions[0].field = { ref: '7f9ff453-7699-4200-95b2-8d55d4eeaf04' };
      this.currentQuestion = this.questions[0];
      this.currentQuestion.field = { ref: 'ae300560-c0d3-4548-aa64-f01f4baa9d9e' };
      this.percentage = (100) / this.questions.length;
      this.changePercentage();
    }, error => {
      if (error.status === 403) {
        this.utilService.changeMessage('Questionnaire Expired');
      } else if (error.status === 409) {
        this.utilService.changeMessage('Questionnaire already submitted');
      }
    });
  }
  next() {
    this.error = null;
    this.setValueInForm();
    const id = this.currentQuestion.newPosition + 1;
    this.currentQuestion = id <= this.questions.length ? this.questions[id - 1] : this.currentQuestion;
    this.percentage = (this.currentQuestion.newPosition * 100) / this.questions.length;
    this.changePercentage();
    this.setFormatDate();
  }

  previous() {
    this.error = null;
      const id = this.currentQuestion.newPosition - 1;
      this.currentQuestion = id > 0 && id <= this.questions.length ? this.questions[id - 1] : this.currentQuestion;
      this.percentage = (this.currentQuestion.newPosition * 100) / this.questions.length;
      this.changePercentage();
      this.setFormatDate();


  }

  selectAnswer(response: number) {
    this.currentQuestion.number = response;
    setTimeout(() => {
      this.next();
    }, 100);
  }

  changePercentage() {
    const bar = document.getElementById('progress-bar');
    bar.style.width = this.percentage + '%';
  }

  onSubmit() {

    this.error = null;
    this.setValueInForm();
    const formIsValid = [];
    this.questions.forEach(item => {
      if (!item.field || !item.field.ref) {
        item.field = { ref: uuid.v4() };
      }
      if (item.type === 'email') {
        item.email = item.text;
      }
      if (item.type === 'date') {
        item.date = item.text;
      }

      item.field.id = item.base_question_id;
      formIsValid.push(this.validateForm(item));

    });

    if (formIsValid.filter(x => x === false).length > 0) {
      this.error = 'error';
      this.setFormatDate();
    } else {
      this.error = null;
      if (formIsValid) {
        this.loading = true;
        this.questions.push({
            base_question_id: null,
            down_title: null,
            middle_title: null,
            position: null,
            text: localStorage.getItem('advisor_code'),
            title: 'advisor Code',
            type: 'text',
            up_title: null,
            field: {
              ref: 'abe84a7d-3ec8-4c36-9a22-9f432f93ecbc'
            }
          });
        this.sendResults();
      }
    }
  }

  setValueInForm() {
    if (this.currentQuestion.type !== 'number') {
      try {
        const value = (<HTMLInputElement>document.getElementById('response-text-value')).value;
        if (value) {
          this.currentQuestion.text = value;
        }
        if (value && this.currentQuestion.isLast) {
          this.currentQuestion.phone_number = this.phonePrefix + value;
        }
      } catch (e) {
        console.warn(e);
      }
    }

  }

  setNameInQuestion23() {
    try {
      const question = this.questions.find(item => item.newPosition === 24);
      if (!question.isCorrect && this.questions[0].text) {
        question.title = question.title.replace('{{client_name}}', this.questions[0].text);
        question.isCorrect = true;
      }
    } catch (e) {
      console.warn(e);
    }
  }

  setFormatDate() {
    try {
      const question = this.questions.find(item => item.newPosition === 2);
      if (!question.isCorrect && this.questions[0].text && this.currentQuestion.newPosition === 2) {
        setTimeout(() => {
          if (this.currentQuestion.text) {
            this.inputDate.nativeElement.setAttribute('data-date',
            moment(this.currentQuestion.text, 'YYYY-MM-DD')
            .format( this.inputDate.nativeElement.getAttribute('data-date-format') ));
          } else {
            this.inputDate.nativeElement.setAttribute('data-date', 'mm/dd/yyyy');
          }

        }, 0);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  modelChangeFn(event) {
    this.inputDate.nativeElement.setAttribute('data-date',
      moment(event, 'YYYY-MM-DD')
      .format( this.inputDate.nativeElement.getAttribute('data-date-format') ));
}


  countrySelected(prefix) {
    this.phonePrefix = prefix;
  }

  validateForm(item: any): boolean {
    const result = !!item[item.type];
    this.currentQuestion = result ? this.currentQuestion : item;
    return result;
  }

  goHome() {
    window.location.href = environment.ladingUrl;
  }

  /**
   * closeModal()
   * hide the questionnaire form
   * @memberof QuestionnaireComponent
   */
   closeModal() {
    this.ref.close();
  }


  sendResults() {
    this.rpsService.sendResults(this.questions, 'launch', this.Jwt).subscribe({
      next: res => {
        if (res['message'] === 'answers has been added') {
          this.submit = true;
          this.loading = false;
        }
      },
      error: error => {
        this.loading = false;
        if (error.status === 409) {
          this.utilService.changeMessage('Questionnaire already submitted');
        }
      }
    });
  }

}
